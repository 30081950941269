import { CircularProgress } from "@mui/material";
import { useContext, useEffect } from "react";
import DashboardTableContainer from "../../containers/DashboardTableContainer";
import PersonPickerContainer from "../../containers/PersonPickerContainer";
import YearPickerContainer from "../../containers/YearPickerContainer";
import { UserContext } from "../../context/user/UserContext";
import { StyledGlobalDivDashboard, StyledTableContainer } from "./styles";

const Dashboard = ({
  collabSelected,
  yearPicked,
  getDashboardByYearAndCollab,
  isLoadingDashboard,
  dashboardData,
  updateCollabSelected,
}) => {
  const [userContext] = useContext(UserContext);

  useEffect(() => {
    if (userContext && !collabSelected?.username) {
      updateCollabSelected(userContext.profile);
    }
  }, [collabSelected, updateCollabSelected, userContext]);

  useEffect(() => {
    if (collabSelected && yearPicked) {
      getDashboardByYearAndCollab(yearPicked, collabSelected);
    }
  }, [collabSelected, getDashboardByYearAndCollab, yearPicked]);

  return (
    <StyledGlobalDivDashboard>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "inherit",
          alignItems: "center",
          height: "10%",
        }}
      >
        <YearPickerContainer />
        <PersonPickerContainer />
      </div>
      {isLoadingDashboard ? (
        <CircularProgress style={{ alignSelf: "center" }} />
      ) : collabSelected ? (
        <StyledTableContainer>
          {dashboardData.map((dashboardBytab, i) => (
            <DashboardTableContainer key={i} dashboardBytab={dashboardBytab} />
          ))}
        </StyledTableContainer>
      ) : (
        <div>Merci de choisir un collaborateur</div>
      )}
    </StyledGlobalDivDashboard>
  );
};

export default Dashboard;
