/* eslint-disable no-unused-vars */
import { LOGIN_ERROR, REGISTER_ERROR, UNKNOWN_ERROR } from "../utils/errors";
import { axiosInstance } from "../App";

export const login = async ({ queryKey }) => {
  const [_key, { username, password }] = queryKey;
  return axiosInstance
    .post(
      "users/login",
      {
        username,
        password,
      },
      { withCredentials: true }
    )
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(
        LOGIN_ERROR[err?.response?.status]
          ? LOGIN_ERROR[err?.response?.status]
          : UNKNOWN_ERROR
      );
    });
};

export const getRefreshToken = async () => {
  const { data } = await axiosInstance.post(
    "users/refreshToken",
    {},
    { withCredentials: true }
  );
  return data;
};

export const logout = async ({ queryKey }) => {
  const [_key, token] = queryKey;
  const { data } = await axiosInstance.get("users/logout");

  return data;
};

export const addUser = (params) => {
  return axiosInstance
    .put("users", params)
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const deleteUser = (userId) => {
  console.log(userId);
  return axiosInstance
    .delete("users/" + userId)
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const updateUser = (params) => {
  return axiosInstance
    .post("users", params)
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const getRoles = () => {
  return axiosInstance.get("users/roles", {}).then((response) => response.data);
};

export const getMyProfile = () => {
  return axiosInstance.get("users/me", {});
};

export const getUsers = () => {
  return axiosInstance.get("users", {}).then((response) => {
    const users = response.data;
    users.push({ firstName: "N/A", lastName: "", username: "N/A" });
    return users;
  });
};
