import { combineReducers } from "redux";
import types from "./types";

const tabsConfig = (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_TABS_CONFIG: {
      const temp = { ...state };
      action.payload.tabsConfig.map((tab) => {
        return (temp[tab.id] = {
          columns: tab.columns,
          id: tab.id,
          name: tab.name,
          linked: tab.linked,
          visibility: tab.visibility,
          clientsColumnsVisibled: tab.clientsColumnsVisibled,
        });
      });
      return temp;
    }
    default:
      return state;
  }
};

const tabsLoading = (state = false, action) => {
  switch (action.type) {
    case types.IS_LOADING_GET_TABS: {
      return true;
    }
    case types.UPDATE_TABS_CONFIG: {
      return false;
    }
    case types.UPDATE_TAB_DATA: {
      return false;
    }
    default:
      return state;
  }
};

const tabsContent = (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_TAB_DATA: {
      return { ...state, [action.payload.ongletID]: action.payload.tabData };
    }
    default:
      return state;
  }
};

const tabSelected = (state = null, action) => {
  switch (action.type) {
    case types.UPDATE_TAB_SELECTED: {
      return action.payload.ongletID;
    }
    default:
      return state;
  }
};

const filters = (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_FILTERS: {
      return {
        ...state,
        [action.payload.tabSelected]: {
          ...state[action.payload.tabSelected],
          [action.payload.filterKey]: {
            ...action.payload.value,
            columnName: action.payload.columnName,
          },
        },
      };
    }
    case types.RESET_FILTERS: {
      return {
        ...state,
        [action.payload.tabSelected]: {},
      };
    }
    default:
      return state;
  }
};

const location = (state = "null", action) => {
  switch (action.type) {
    case types.UPDATE_ROUTE_LOCATION: {
      return action.payload.location;
    }
    default:
      return state;
  }
};

export default combineReducers({
  tabsConfig,
  tabSelected,
  tabsLoading,
  tabsContent,
  filters,
  location,
});
