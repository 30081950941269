import { Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosContext } from "../context/axios/AxiosProvider";
import { UserContext } from "../context/user/UserContext";
import { useLogout } from "../queries/user";
import { LOGIN } from "../routes/utils";

const Logout = () => {
  const [userContext, setUserContext] = useContext(UserContext);
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { removeInterceptor } = useContext(AxiosContext);

  useLogout(userContext.token, {
    enabled: isSubmitted,
    onSuccess: (data) => {
      removeInterceptor();
      setUserContext((oldValues) => {
        return { ...oldValues, details: undefined, token: null, profile: null };
      });
      setIsSubmitted(false);
      navigate(LOGIN);
    },
    onErrorCaptured: () => {
      setIsSubmitted(false);
    },
  });

  return (
    <Button
      onClick={() => {
        setIsSubmitted(true);
      }}
      variant="contained"
      color="internalSecondary"
    >
      DÉCONNEXION
    </Button>
  );
};

export default Logout;
