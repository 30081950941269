import styled from "styled-components";
import { css } from "@emotion/css";

export const StyledGestionUtilisateurs = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 0 5rem;
`;

export const StyledUserList = styled("div")`
  padding: 2rem 15rem;
`;

export const StyledUserRow = styled("div")`
  display: flex;
  justify-content: space-between;
  border: 1px solid aliceblue;
  padding: 1rem 2rem;
  font-size: large;
  font-weight: 500;
  :hover {
    background-color: #1976d2;
    color: white;
    cursor: pointer;
  }
`;

export const StyledRow = css`
  :hover {
    cursor: pointer;
    background-color: #1976d2;
    color: white;
  }
`;
