import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLogin } from "../../queries/user";
import { UserContext } from "../../context/user/UserContext";
import { useNavigate } from "react-router-dom";
import { HOME } from "../../routes/utils";
import InputTextField from "../Widgets/InputTextField";
import { Alert } from "@mui/material";
import Card from "../Widgets/Card";
import MyForm from "../Widgets/Form";
import { AxiosContext } from "../../context/axios/AxiosProvider";
import ButtonComponent from "../Widgets/Button";
import { StyledLogin } from "./styles";

const Login = () => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [userContext, setUserContext] = useContext(UserContext);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Pseudo requis"),
      password: Yup.string().required("Mot de passe requis"),
    }),
    onSubmit: (values) => {
      setIsFormValid(true);
    },
  });
  const { setInterceptor } = useContext(AxiosContext);

  const { error } = useLogin(formik.values, {
    enabled: isFormValid,
    onSuccess: (data) => {
      setInterceptor(data.token);
      setUserContext((oldValues) => {
        return { ...oldValues, token: data.token };
      });
      setIsFormValid(false);
      navigate(HOME);
    },
  });

  useEffect(() => {
    setIsFormValid(false);
  }, [error]);

  useEffect(() => {
    if (userContext.token) {
      navigate(HOME);
    }
  }, [navigate, userContext]);

  const [haveForgottenIdentifiant, setHaveForgottenIdentifiant] =
    useState(false);

  return (
    <StyledLogin>
      <Card>
        <h2>Bienvenue sur le TaxPlanning</h2>
        <MyForm onSubmit={formik.handleSubmit}>
          {haveForgottenIdentifiant ? (
            <div>
              Contact Sacha ou Morgan pour qu'ils t'envoient de nouveaux
              identifiants
            </div>
          ) : (
            <>
              <InputTextField
                formik={formik}
                formikItemKey="username"
                label="Pseudo"
              />
              <InputTextField
                type="password"
                formik={formik}
                formikItemKey="password"
                label="Mot de Passe"
              />
            </>
          )}

          <div style={{ justifyContent: "space-between" }}>
            {haveForgottenIdentifiant ? (
              <ButtonComponent
                variant="text"
                title="C'est bon je m'en souviens"
                onClickMethod={() => {
                  setHaveForgottenIdentifiant(false);
                }}
              />
            ) : (
              <>
                <ButtonComponent
                  style={{ marginRight: "2rem" }}
                  variant="text"
                  title="Difficultés à se connecter ?"
                  onClickMethod={() => {
                    setHaveForgottenIdentifiant(true);
                  }}
                />
                <ButtonComponent submit title="Se connecter" />
                {error && (
                  <Alert style={{ marginTop: "1rem" }} severity="error">
                    {error.message}
                  </Alert>
                )}
              </>
            )}
          </div>
        </MyForm>
      </Card>
    </StyledLogin>
  );
};

export default Login;
