import { connect } from "react-redux";

import GestionClients from "../components/GestionClients";
import { updateClients, updateClient } from "../services/client";
import { clientActions, clientSelectors } from "../store/client";
import { tabSelectors } from "../store/tab";
import { toastActions } from "../store/toast";

export default connect(
  (state) => ({
    isLoadingClients: clientSelectors.isLoadingClients(state),
    clientsRows: clientSelectors.clientsGeneralConfigRows(state),
    clientsColumns: clientSelectors.clientsGeneralConfigColumns(state),
    tabsList: tabSelectors.tabsList(state),
    filters: tabSelectors.filtersConfigClient(state),
  }),
  (dispatch) => ({
    updateClients: (clients) => {
      dispatch(clientActions.startGettingClients());
      return updateClients(clients)
        .then((data) => {
          dispatch(clientActions.updateClientList(data));
          dispatch(toastActions.updateToast("Clients mis à jour avec succès"));
        })
        .catch((err) => {
          dispatch(toastActions.updateToast(err.message, "error"));
        });
    },
    updateClient: (client) => {
      dispatch(clientActions.startGettingClients());
      updateClient(client)
        .then((data) => {
          dispatch(clientActions.updateClientList(data));
          dispatch(toastActions.updateToast("Clients mis à jour avec succès"));
        })
        .catch((err) => {
          dispatch(toastActions.updateToast(err.message, "error"));
        });
    },
    handleCopyPasteDifferentType: () => {
      dispatch(
        toastActions.updateToast(
          "Attention tu peux pas copier 2 colonnes de types différents",
          "error"
        )
      );
    },
  }),
  (stateToProps, dispatchToProps, ownProps) => ({
    ...stateToProps,
    ...dispatchToProps,
    ...ownProps,
  })
)(GestionClients);
