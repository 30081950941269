import styled from "styled-components";
import DataGrid from "react-data-grid";

export const StyledGlobalDivTabData = styled("div")`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const StyledDataGrid = styled(DataGrid)`
  height: 90%;
  color: ${({ theme }) => theme.palette.internalPrimary.main};
`;


