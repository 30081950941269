import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useContext } from "react";
import { useRefreshToken, useMe } from "../../queries/user";
import { AxiosContext } from "../axios/AxiosProvider";

const UserContext = React.createContext([{}, () => {}]);

let initialState = {};

const UserProvider = (props) => {
  const [state, setState] = useState(initialState);
  const { setInterceptor } = useContext(AxiosContext);

  const { isLoading } = useRefreshToken({
    onSuccess: (data) => {
      setInterceptor(data.token);
      setState({ token: data.token });
    },
    onErrorCaptured: () => {
      setState({});
    },
  });

  const { isLoading: isLoadingMe } = useMe({
    enabled: !!state.token,
    onSuccess: ({ data }) => {
      setState({
        ...state,
        profile: data,
      });
    },
    onErrorCaptured: () => {
      setState({});
    },
  });

  return (
    <UserContext.Provider value={[state, setState]}>
      {isLoading || isLoadingMe ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        props.children
      )}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
