import { Button, MenuItem } from "@mui/material";
import styled from "styled-components";
import LoginBackground from "../../assets/img/login_background.jpg";
import { ADMIN_PAGES } from "../../routes/utils";

export const StyledDiv = styled("div")`
  /* background-image: ${`url(${LoginBackground})`}; */
  background-color: white;
  background-position: center;
  background-size: cover;
  flex-grow: 1;
`;

export const StyledMain = styled("div")`
  display: flex;
  align-content: center;
  justify-content: center;
  height: calc(100vh - 56px);
  width: 100vw;
  max-width: 100%;
  ${({ theme }) =>
    `${theme.breakpoints.up("xs")} and (orientation: landscape)`} {
    height: calc(100vh - 48px);
  }
  ${({ theme }) => `${theme.breakpoints.up("sm")}`} {
    height: calc(100vh - 64px);
  }
  overflow-y: scroll;
`;

export const StyledTabAppBar = styled("div")`
  background-color: ${({ location, onglet, theme }) =>
    location?.state?.id === onglet.id &&
    theme.palette.internalPrimary.contrastText};
  border-radius: 1em;
  border-color: ${({ theme }) => theme.palette.internalPrimary.main};
  border: 1px solid;
`;

export const StyledRegularButtonContainer = styled("div")`
  background-color: ${({ location, route, theme }) =>
    location.pathname === route && theme.palette.internalPrimary.contrastText};
  border-radius: 1em;
  border-color: ${({ theme }) => theme.palette.internalPrimary.main};
  border: 1px solid;
`;

export const StyledButton = styled(Button)`
  color: ${({ location, onglet, theme }) =>
    location?.state?.id === onglet.id
      ? theme.palette.internalPrimary.main
      : theme.palette.internalPrimary.contrastText};
`;

export const StyledDivMenuAppBar = styled("div")`
  display: flex;
  background-color: ${({ theme }) => theme.palette.internalPrimary.main};
  border-radius: 1em;
`;

export const StyledButtonMenuDeroulant = styled(Button)`
  color: ${({ location, theme }) => {
    return ADMIN_PAGES.includes(location.pathname)
      ? theme.palette.internalPrimary.main
      : theme.palette.internalPrimary.contrastText;
  }};
  background-color: ${({ location, theme }) => {
    return ADMIN_PAGES.includes(location.pathname)
      ? theme.palette.internalPrimary.contrastText
      : theme.palette.internalPrimary.main;
  }};
  border-radius: 1em;
  border-color: ${({ theme }) => theme.palette.internalPrimary.main};
  border: ${({ location, theme }) => {
    return ADMIN_PAGES.includes(location.pathname) ? "1px solid" : "";
  }};
  :hover {
    background-color: ${({ theme }) => theme.palette.internalPrimary.main};
    color: white;
  }
`;

export const StyledRegularButton = styled(Button)`
  color: ${({ location, theme, route }) => {
    return location.pathname === route
      ? theme.palette.internalPrimary.main
      : theme.palette.internalPrimary.contrastText;
  }};
`;

export const StyledMenuItem = styled(MenuItem)`
  background-color: ${({ location, link, theme }) => {
    return location.pathname === link && theme.palette.internalPrimary.main;
  }};
  color: ${({ location, link }) => {
    return location.pathname === link && "white";
  }};
  border-radius: 1em;
  :hover {
    background-color: ${({ location, link, theme }) =>
      theme.palette.internalPrimary.main};
    color: ${({ location, link }) => {
      return "white";
    }};
    border-radius: 1em;
  }
`;

export const StyledLogo = styled("img")`
  height: 50px;
  :hover {
    cursor: pointer;
  }
`;
