/* eslint-disable import/no-anonymous-default-export */
import { createSelector } from "reselect";
import clientSelectors from "../client/selectors";
import {
  chooseRightEditorByColumnType,
  chooseRightFormatterByColumnType,
  chooseRightHeaderFilterByColumnType,
  CLIENTS_HEADER,
} from "./utils";
import userSelectors from "../user/selectors";
import { CONFIG_CLIENTS } from "../../routes/utils";

const tabsConfig = (state) => state.tabs.tabsConfig;
const tabsContent = (state) => state.tabs.tabsContent;
const tabSelected = (state) =>
  state.tabs.location === CONFIG_CLIENTS
    ? "configClient"
    : state.tabs.tabSelected;

const tabsLoading = (state) => state.tabs.tabsLoading;
const filters = (state) => state.tabs.filters;

const tabSelectedConfig = createSelector(
  [tabsConfig, tabSelected],
  (config, tabSelected) => {
    let columnsObject = {};
    config[tabSelected]?.columns?.forEach((column) => {
      columnsObject = {
        ...columnsObject,
        [column.id]: column,
      };
    });
    return {
      ...config[tabSelected],
      columns: columnsObject,
    };
  }
);

const tabContentSelected = createSelector(
  [tabSelected, tabsContent],
  (tabSelected, contents) => contents[tabSelected] ?? []
);

const tabSelectedColumnHeader = createSelector(
  [
    tabSelectedConfig,
    userSelectors.userList,
    clientSelectors.clientLinkedTab,
    tabContentSelected,
  ],
  (configSelected, users, clientContent, tabContentSelected) => {
    const finalColumn = [];
    if (configSelected) {
      const TEMP_COLUMNS_ADDED = Object.keys(configSelected.columns)
        .map((columnKey) => {
          const columnConfig = configSelected.columns[columnKey];
          return {
            width: 150,
            position: columnConfig.position,
            key: columnConfig.id,
            name: columnConfig.name,
            subName: columnConfig.subName,
            editor: (props) =>
              chooseRightEditorByColumnType(
                columnConfig.type,
                users,
                props,
                columnConfig.options
              ),
            editorOptions: {
              onNavigation(e) {
                const { key } = e;
                if (key === "Tab") {
                  return true;
                }
                return false;
              },
              editOnClick: true,
              commitOnOutsideClick: true,
            },
            formatter: (props) =>
              chooseRightFormatterByColumnType(columnConfig.type, users, props),
            headerRenderer: (p) => {
              return chooseRightHeaderFilterByColumnType(
                columnConfig.type,
                p,
                columnConfig.options,
                tabContentSelected
              );
            },
          };
        })
        .sort((c1, c2) => c2 - c1);
      if (configSelected.linked) {
        finalColumn.push(
          ...CLIENTS_HEADER(users, clientContent).filter(
            (column) => configSelected?.clientsColumnsVisibled?.[column.key]
          )
        );
      }
      finalColumn.push(...TEMP_COLUMNS_ADDED);
    }
    return finalColumn;
  }
);

const tabSelectedRows = createSelector(
  [
    tabSelectedColumnHeader,
    clientSelectors.clientLinkedTab,
    tabSelectedConfig,
    tabContentSelected,
  ],
  (columns, clients, tabSelectedConfig, tabContent = []) => {
    let ROWS = [];
    if (tabSelectedConfig?.linked) {
      const tempRows = clients.map((client) => {
        let row = {};
        row.id = client._id;
        columns.forEach((column) => {
          row = {
            ...row,
            [column.key]: client[column.key],
          };
        });
        const tempContent = tabContent.filter(
          (item) => client._id === item.client_id
        );
        if (tempContent.length > 0) {
          row = {
            ...row,
            ...tempContent[0],
          };
        }
        return row;
      });
      ROWS.push(...tempRows);
    }
    return ROWS;
  }
);

const tabsList = createSelector(
  [tabsConfig],
  (tabs) =>
    Object.keys(tabs).map((tabKey) => {
      return {
        id: tabs[tabKey].id,
        name: tabs[tabKey].name,
        visibility: tabs[tabKey].visibility,
        linked: tabs[tabKey].linked,
      };
    }) ?? []
);

const filtersTabSelected = createSelector(
  [filters, tabSelected],
  (filters, tabSelected) => {
    return tabSelected ? filters[tabSelected] : {};
  }
);

const filtersConfigClient = createSelector([filters], (filters) => {
  return filters["configClient"];
});

const tabsColumnsMapNamed = createSelector([tabsConfig], (config) => {
  const columnNameMap = {};
  Object.keys(config).forEach((tabKey) => {
    config[tabKey].columns.forEach((column) => {
      columnNameMap[column.id] = column;
    });
  });
  return columnNameMap;
});

export default {
  tabsConfig,
  tabsContent,
  tabContentSelected,
  tabSelected,
  tabSelectedRows,
  tabsLoading,
  tabsList,
  tabSelectedConfig,
  tabSelectedColumnHeader,
  filtersTabSelected,
  filtersConfigClient,
  tabsColumnsMapNamed,
};
