import { IconButton, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import ButtonComponent from "../Widgets/Button";
import AlertComponent from "../Widgets/Alert";
import { COLONNE_TYPES } from "./utils";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const AddColumnComponent = ({ addColumns, cancelAddingColumns }) => {
  const [colonne, setColonne] = useState({
    name: "",
    subName: null,
    type: COLONNE_TYPES[0].key,
    options: null,
    position: null,
  });

  const [displayError, setDisplayError] = useState(null);

  const handleChangeColonneType = (e) => {
    setDisplayError(null);
    const target = e.target.value;
    setColonne({
      ...colonne,
      type: target,
      options: target === "multi" ? [""] : null,
    });
  };

  const handleChangeOption = (e, i) => {
    setDisplayError(null);
    const temp = [...colonne.options];
    temp[i] = e.target.value;
    setColonne({
      ...colonne,
      options: temp,
    });
  };

  const handleAddNewOptions = () => {
    setDisplayError(null);
    const temp = [...colonne.options];
    temp.push("");
    setColonne({
      ...colonne,
      options: temp,
    });
  };

  const deleteOptions = (i) => {
    const temp = [...colonne.options];
    temp.splice(i, 1);
    setColonne({
      ...colonne,
      options: temp,
    });
  };

  const validateColumn = () => {
    const tempColonne = {
      ...colonne,
      options: colonne.options?.filter((opt) => opt.trim().length !== 0),
    };
    if (colonne.name.trim().length > 0) {
      if (colonne.type === "multi") {
        if (tempColonne.options.length > 1) {
          addColumns(tempColonne);
        } else {
          setDisplayError("Minimum 2 options valides");
        }
      } else {
        addColumns(tempColonne);
      }
    } else {
      setDisplayError("Le nom de la colonne doit être remplie");
    }
  };

  return (
    <>
      Titre de la nouvelle colonne:
      {displayError && <AlertComponent severity="error" msg={displayError} />}
      <TextField
        required
        id="nom_colonne"
        variant="outlined"
        value={colonne.name}
        onChange={(e) => {
          setColonne({
            ...colonne,
            name: e.target.value,
          });
        }}
      />
      Sous-titre de la nouvelle colonne (non-obligatoire)
      <TextField
        id="subnom_colonne"
        variant="outlined"
        value={colonne.subName}
        onChange={(e) => {
          setColonne({
            ...colonne,
            subName: e.target.value,
          });
        }}
      />
      Type de colonne:
      <Select
        labelId="colonne-type"
        id="colonne-type"
        value={colonne.type}
        label="Type"
        onChange={handleChangeColonneType}
      >
        {COLONNE_TYPES.map((c) => (
          <MenuItem key={c.name} value={c.key}>
            {c.name}
          </MenuItem>
        ))}
      </Select>
      {colonne.type === "multi" &&
        colonne.options.map((option, i) => (
          <div style={{ display: "flex" }}>
            <TextField
              placeholder="Option 1"
              id={i}
              variant="outlined"
              value={option}
              onChange={(e) => handleChangeOption(e, i)}
            />
            <IconButton>
              <RemoveCircleIcon
                color="internalSecondary"
                onClick={() => {
                  deleteOptions(i);
                }}
              />
            </IconButton>
            <IconButton>
              <AddCircleIcon
                color="internalPrimary"
                onClick={handleAddNewOptions}
              />
            </IconButton>
          </div>
        ))}
      <div style={{ display: "flex", gap: "10%", justifyContent: "center" }}>
        <ButtonComponent title="Annuler" onClickMethod={cancelAddingColumns} />
        <ButtonComponent
          color="success"
          title="Valider"
          onClickMethod={validateColumn}
        />
      </div>
    </>
  );
};

export default AddColumnComponent;
