import { IconButton, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import ButtonComponent from "../Widgets/Button";
import MyModal from "../Widgets/Modal";
import { COLONNE_TYPES } from "./utils";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
const EditColumnComponent = ({
  column,
  handleValidateColumnModification,
  cancelEditColumn,
  deleteColumn,
}) => {
  const [colonne, setColonne] = useState(column);

  const [savedOptions, setSavedOptions] = useState([]);
  const [newOptions, setNewOptions] = useState([]);

  useEffect(() => {
    if (colonne.type === "multi") {
      setSavedOptions(colonne.options);
    }
  }, [colonne]);

  const handleChangeOption = (e, i) => {
    const temp = [...newOptions];
    temp[i] = e.target.value;
    setNewOptions(temp);
  };

  const handleAddNewOptions = () => {
    const temp = [...newOptions];
    temp.push("");
    setNewOptions(temp);
  };

  const deleteOptions = (i) => {
    const temp = [...newOptions];
    temp.splice(i, 1);
    setNewOptions(temp);
  };

  const validateColumn = () => {
    const finalColumn = colonne;
    if (colonne.type === "multi") {
      finalColumn.options = [...savedOptions, ...newOptions];
    }
    handleValidateColumnModification(finalColumn);
  };

  const handleValidateDeleteColumn = () => {
    setModalOpened(false);
    deleteColumn();
  };

  const [openModal, setModalOpened] = useState(false);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <TextField
        id="nom_colonne"
        placeholder="Titre"
        variant="outlined"
        value={colonne.name}
        onChange={(e) => {
          setColonne({
            ...colonne,
            name: e.target.value,
          });
        }}
      />
      <TextField
        id="subnom_colonne"
        variant="outlined"
        placeholder="Sous-Titre"
        value={colonne.subName}
        onChange={(e) => {
          setColonne({
            ...colonne,
            subName: e.target.value,
          });
        }}
      />
      <Select
        labelId="colonne-type"
        id="colonne-type"
        value={colonne.type}
        label="Type"
        disabled
      >
        {COLONNE_TYPES.map((c) => (
          <MenuItem key={c.name} value={c.key}>
            {c.name}
          </MenuItem>
        ))}
      </Select>
      {colonne.type === "multi" && (
        <div>
          <span>Options enregistrées:</span>
          {savedOptions.map((option, i) => (
            <>
              <div>{option}</div>
            </>
          ))}
        </div>
      )}
      {colonne.type === "multi" &&
        newOptions.map((option, i) => (
          <div style={{ display: "flex" }}>
            <TextField
              placeholder="Option 1"
              id={i}
              variant="outlined"
              value={option}
              onChange={(e) => handleChangeOption(e, i)}
            />
            <IconButton>
              <RemoveCircleIcon
                color="internalSecondary"
                onClick={() => {
                  deleteOptions(i);
                }}
              />
            </IconButton>
            <IconButton>
              <AddCircleIcon
                color="internalPrimary"
                onClick={handleAddNewOptions}
              />
            </IconButton>
          </div>
        ))}
      <div style={{ display: "flex", gap: "5%" }}>
        {colonne.type === "multi" && (
          <ButtonComponent
            title="Ajouter Options"
            onClickMethod={handleAddNewOptions}
          />
        )}
        <ButtonComponent
          color="internalSecondary"
          title="Supprimer"
          onClickMethod={() => {
            setModalOpened(true);
          }}
        />
        <ButtonComponent title="Annuler" onClickMethod={cancelEditColumn} />
        <ButtonComponent
          color="success"
          title="Valider"
          onClickMethod={validateColumn}
        />
      </div>
      <MyModal
        title={"Suppression de la colonne " + colonne.name}
        open={openModal}
        handleCancel={() => {
          setModalOpened(false);
        }}
        handleValidate={handleValidateDeleteColumn}
      >
        Ëtes-vous sûr de vouloir supprimer cette colonne ? Cela entraînera une
        suppression de toutes les données associées à cette colonne.
      </MyModal>
    </div>
  );
};

export default EditColumnComponent;
