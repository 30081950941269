export const LOGIN = "/login";
export const HOME = "/home";
export const ME = "/me";
export const ADD_USER = "add-user";
export const ADD_CLIENT = "add-client";
export const TAB = "tab";
export const CONFIG_ONGLETS = "/config-onglet";
export const CONFIG_USERS = "/users";
export const CONFIG_CLIENTS = "/clients";
export const ADMIN_PAGES = [CONFIG_CLIENTS, CONFIG_USERS, CONFIG_ONGLETS];
