import DatePicker from "react-datepicker";
import { useTheme } from "@mui/material";
import { forwardRef, useState } from "react";
import ButtonComponent from "../Widgets/Button";
import EventIcon from "@mui/icons-material/Event";
import { StyledWrapperDatePicker } from "./styles";

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <ButtonComponent
    style={{ marginTop: 0 }}
    variant="contained"
    onClickMethod={onClick}
    ref={ref}
    title={value}
    endIcon={<EventIcon />}
  >
    {value}
  </ButtonComponent>
));

const YearPicker = ({ updateYearPicked, yearSelected }) => {
  const [yearPicked, setYearPicked] = useState(
    yearSelected ? yearSelected : new Date().getFullYear()
  );

  const handleChangeSelectedYear = (newYear) => {
    setYearPicked(newYear.getFullYear());
    updateYearPicked(newYear.getFullYear());
  };

  const theme = useTheme();
  return (
    <StyledWrapperDatePicker theme={theme}>
      <DatePicker
        selected={new Date(yearPicked, 1, 1)}
        onChange={handleChangeSelectedYear}
        customInput={<ExampleCustomInput />}
        showYearPicker
        dateFormat="yyyy"
        yearItemNumber={3}
        minDate={new Date(new Date().getFullYear() - 5, 1, 2)}
        maxDate={new Date(new Date().getFullYear(), 1, 2)}
      />
    </StyledWrapperDatePicker>
  );
};

export default YearPicker;
