import { axiosInstance } from "../App";

export const addClient = (params) => {
  return axiosInstance
    .put("clients", params)
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const getClientList = () => {
  return axiosInstance
    .get("clients")
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const updateClients = (params) => {
  return axiosInstance
    .post("clients", { clients: params })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const updateClient = (params) => {
  return axiosInstance
    .post("clients/updateOne", { client: params })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};
