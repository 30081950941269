import { connect } from "react-redux";
import { getTabData } from "../services/tab";

import { tabSelectors, tabActions } from "../store/tab";
import { toastActions } from "../store/toast";

import OngletWrapper from "../components/OngletWrapper";
import { getOnglets } from "../services/system";
import { clientSelectors } from "../store/client";

export default connect(
  (state) => ({
    tabSelected: tabSelectors.tabSelected(state),
    tabsList: tabSelectors.tabsList(state),
    tabsConfig: tabSelectors.tabsConfig(state),
    tabsContent: tabSelectors.tabsContent(state),
    clientLinkedTab: clientSelectors.clientLinkedTab(state),
    tabSelectedRows: tabSelectors.tabSelectedRows(state),
    yearSelected: clientSelectors.yearSelected(state),
  }),
  (dispatch) => ({
    getTabData: (ongletID, year) => {
      getTabData(ongletID, year)
        .then((data) => {
          dispatch(tabActions.updateTab(ongletID, data));
        })
        .catch((err) => {
          dispatch(toastActions.updateToast(err.message, "error"));
        });
    },
    getOngletsConfig: () => {
      dispatch(tabActions.startGettingTabs());
      getOnglets()
        .then((data) => {
          dispatch(tabActions.updateTabsConfig(data));
        })
        .catch((err) => {
          dispatch(toastActions.updateToast(err.message, "error"));
        });
    },
    updateTabSelected: (ongletID) => {
      dispatch(tabActions.updateTabSelected(ongletID));
    },
  })
)(OngletWrapper);
