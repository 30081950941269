import { format } from "date-fns";

export const checkRightFilter = (filterType, row, filters, filterKey) => {
  switch (filterType) {
    case "text": {
      if (filters[filterKey].value) {
        const stringRow = row?.toString();
        return filters[filterKey].value.trim().length > 0
          ? stringRow
              ?.toLowerCase()
              .includes(filters[filterKey].value?.toLowerCase())
          : !stringRow || stringRow.trim().length === 0;
      } else {
        return true;
      }
    }
    case "person": {
      if (filters[filterKey].value) {
        return filters[filterKey].value.trim().length > 0
          ? row?.some((e) =>
              e.username
                ?.toLowerCase()
                .includes(filters[filterKey].value.toLowerCase())
            )
          : !row;
      } else {
        return true;
      }
    }
    case "multi":
    case "ok": {
      if (filters[filterKey].value.length > 0) {
        return filters[filterKey].value.some((item) => {
          return item.length > 0 ? item === row : !row;
        });
      } else {
        return true;
      }
    }
    case "boolean": {
      if (row) {
        switch (filters[filterKey].value) {
          case false:
          case true: {
            return filters[filterKey].value === row;
          }
          default:
            return true;
        }
      } else {
        return !filters[filterKey].value;
      }
    }
    case "date": {
      if (filters[filterKey].value.length > 0) {
        return filters[filterKey].value.some((i) => {
          if (row) {
            return i === format(new Date(row), "dd-MM");
          } else {
            return i.trim().length === 0;
          }
        });
      } else {
        return true;
      }
    }
    default:
      return false;
  }
};
