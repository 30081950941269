import { Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { updateTabVisibility } from "../../services/tab";
import ButtonComponent from "../Widgets/Button";

const EditVisibility = ({ tabConfig, tabSelected, getOngletsConfig }) => {
  const [savedVisibilty, setSavedVisibility] = useState(null);
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    if (tabConfig) {
      setSavedVisibility(tabConfig.visibility);
      setVisibility(tabConfig.visibility);
    }
  }, [tabConfig]);

  const mutationVisibility = useMutation(
    () => updateTabVisibility(visibility, tabSelected),
    {
      onSuccess: () => {
        getOngletsConfig();
      },
      onError: () => {},
    }
  );

  const switchVisibility = (e) => {
    setVisibility(e.target.checked);
  };

  const validateVisibility = () => {
    mutationVisibility.mutate();
  };
  return (
    <>
      {savedVisibilty !== null && (
        <>
          <div>
            Visibilité de l'onglet
            <Switch
              checked={visibility}
              onChange={switchVisibility}
              color="secondary"
            />
          </div>
          {visibility !== savedVisibilty && (
            <ButtonComponent
              color="secondary"
              variant="contained"
              title="Valider"
              onClickMethod={validateVisibility}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditVisibility;
