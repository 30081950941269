import { useState } from "react";
import ColumnList from "./ColumnList";
import ButtonComponent from "../Widgets/Button";
import AddColumnComponent from "./AddColumn";
import EditColumnComponent from "./EditColumn";

const ColumnEdition = ({
  myColumns,
  setMyColumns,
  setModificationsCount,
  modificationsCount,
  isAddingColumn,
  setIsAddingColumn,
  isEditingColumn,
  setIsEditingColumn,
}) => {
  const handleChangePosition = (newColumnsOrder) => {
    setMyColumns(
      newColumnsOrder.map((col, index) => ({
        ...col,
        position: index,
      }))
    );
    setModificationsCount(modificationsCount + 1);
  };

  const [editColonneIndex, setEditColonneIndex] = useState(null);

  const editColonneByIndex = (index) => {
    setModificationsCount(modificationsCount + 1);
    setEditColonneIndex(index);
    setIsEditingColumn(true);
  };

  const deleteColumn = () => {
    const temp = [...myColumns];
    temp.splice(editColonneIndex, 1);
    setMyColumns(temp);
    setEditColonneIndex(null);
    setIsEditingColumn(false);
  };

  const cancelEditColumn = () => {
    setModificationsCount(modificationsCount - 1);
    setEditColonneIndex(null);
    setIsEditingColumn(false);
  };

  const addColumns = (column) => {
    const temp = [...myColumns];
    temp.push({ ...column, position: myColumns.length + 1 });
    setMyColumns(temp);
    setIsAddingColumn(false);
  };

  const cancelAddingColumns = () => {
    setModificationsCount(modificationsCount - 1);
    setIsAddingColumn(false);
  };

  const handleValidateColumnModification = (colonne) => {
    const temp = [...myColumns];
    temp[editColonneIndex] = colonne;
    setMyColumns(temp);
    setEditColonneIndex(null);
    setIsEditingColumn(false);
  };

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "90% auto",
          alignItems: "center",
        }}
      >
        <ColumnList
          handleChangePosition={handleChangePosition}
          myColumns={myColumns}
          isAddingColumn={isAddingColumn}
          isEditingColumn={isEditingColumn}
          editColonneByIndex={editColonneByIndex}
        />
        {!isEditingColumn && !isAddingColumn && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ButtonComponent
              style={{ width: "70%" }}
              color="secondary"
              variant="contained"
              title="Ajouter Colonne"
              onClickMethod={() => {
                setModificationsCount(modificationsCount + 1);
                setIsAddingColumn(true);
              }}
            />
          </div>
        )}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: "50%",
          alignSelf: 'center',
        }}
      >
        {isAddingColumn && (
          <AddColumnComponent
            myColumns={myColumns}
            addColumns={addColumns}
            cancelAddingColumns={cancelAddingColumns}
          />
        )}
        {isEditingColumn && (
          <EditColumnComponent
            column={myColumns[editColonneIndex]}
            handleValidateColumnModification={handleValidateColumnModification}
            cancelEditColumn={cancelEditColumn}
            deleteColumn={deleteColumn}
          />
        )}
      </div>
    </>
  );
};

export default ColumnEdition;
