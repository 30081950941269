import { useQuery } from "react-query";
import {
  login,
  getRefreshToken,
  logout,
  getRoles,
  getMyProfile,
  getUsers,
} from "../../services/user";

export const useLogin = (params, options) => {
  return useQuery(["login", { ...params }], login, {
    retry: false,
    ...options,
  });
};

export const useRefreshToken = (options) => {
  return useQuery(["refreshToken"], getRefreshToken, {
    retry: false,
    ...options,
  });
};

export const useLogout = (token, options) => {
  return useQuery(["logout", token], logout, {
    retry: false,
    ...options,
  });
};

export const useRoles = (options) => {
  return useQuery(["roles"], getRoles, { ...options });
};

export const useMe = (options) => {
  return useQuery(["me"], getMyProfile, {
    retry: false,
    ...options,
  });
};

export const useUsers = () => {
  return useQuery(["users"], getUsers);
};
