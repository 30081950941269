import { connect } from "react-redux";

import { tabSelectors, tabActions } from "../store/tab";

import GestionOngletsComponent from "../components/GestionOnglets";

export default connect(
  (state) => ({
    isLoadingOnglets: tabSelectors.tabsLoading(state),
    tabsList: tabSelectors.tabsList(state),
  }),
  (dispatch) => ({
    updateTabsConfig: (data) => {
      dispatch(tabActions.updateTabsConfig(data));
    },
  })
)(GestionOngletsComponent);
