/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import EditClientComponent from "../AddEditClient/EditClient";
import { StyledGlobalDivTabData } from "../TabData/styles";
import DataGrid from "react-data-grid";
import ButtonComponent from "../Widgets/Button";
import { StyledRow } from "./styles";
import { FormControlLabel, Switch, useTheme } from "@mui/material";
import AddClientContainer from "../../containers/AddClientContainer";
import { checkRightFilter } from "../TabHeaders/utils";
import ClientTotal from "../ClientTotal";

const GestionClientsComponent = ({
  isLoadingClients,
  clientsRows,
  clientsColumns,
  updateClients,
  updateClient,
  tabsList,
  filters,
  handleCopyPasteDifferentType,
}) => {
  const [isAddingClient, setIsAddingClientStatus] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [sortColumns, setSortColumns] = useState([]);
  const [clientUpdatedRows, setClientUpdatedRows] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isShowingExpiredClient, setIsShowingExpiredClient] = useState(false);

  const [roleAssocationEditStatus, setRoleAssocationEditStatus] =
    useState(false);

  useEffect(() => {
    if (Object.keys(clientsColumns).length > 0) {
      setColumns(
        Object.keys(clientsColumns).map((columnKey) => ({
          ...clientsColumns[columnKey],
          editable: roleAssocationEditStatus,
        }))
      );
    }
  }, [roleAssocationEditStatus, setColumns, clientsColumns]);

  const filteredRows = useMemo(() => {
    if (filters) {
      return rows.filter((r) => {
        return Object.keys(filters)
          .map((filterKey) => {
            return checkRightFilter(
              filters[filterKey].type,
              r[filterKey],
              filters,
              filterKey
            );
          })
          .every((i) => i);
      });
    } else {
      return rows;
    }
  }, [rows, filters]);

  useEffect(() => {
    setRows(
      isShowingExpiredClient
        ? clientsRows
        : clientsRows.filter((row) => row.expiration_year == null)
    );
  }, [clientsRows, setRows, isShowingExpiredClient]);

  const checkOngletsPresence = (tabId, client) => {
    const onglets = client["onglets"];
    if (client[tabId]) {
      if (onglets.includes(tabId)) {
        return onglets;
      } else {
        return [...onglets, tabId];
      }
    } else {
      if (onglets.includes(tabId)) {
        return onglets.filter((tabIdPresent) => tabIdPresent !== tabId);
      } else {
        return onglets;
      }
    }
  };
  const handleEditClientClick = () => {
    if (roleAssocationEditStatus) {
      let finalUpdates = {};
      Object.keys(clientUpdatedRows).forEach((clientId) => {
        let tempClient = clientUpdatedRows[clientId];
        tabsList.forEach((tab) => {
          tempClient = {
            ...tempClient,
            onglets: checkOngletsPresence(tab.id, tempClient),
          };
          delete tempClient[tab.id];
        });
        finalUpdates = {
          ...finalUpdates,
          [clientId]: {
            ...tempClient,
          },
        };
      });
      updateClients(finalUpdates).then(() => {
        setClientUpdatedRows({});
      });
    }
    setRoleAssocationEditStatus(!roleAssocationEditStatus);
  };

  const handleCancelEdit = () => {
    setRows(clientsRows);
    setRoleAssocationEditStatus(false);
  };

  const handleRowsChange = (rows, data) => {
    if (!roleAssocationEditStatus) {
      setRoleAssocationEditStatus(true);
    }
    setRows(rows);
    setClientUpdatedRows({
      ...clientUpdatedRows,
      [rows[data.indexes[0]]._id]: rows[data.indexes[0]],
    });
  };

  const handleRowClick = (row) => {
    if (!roleAssocationEditStatus) {
      let tempValues = row;
      Object.keys(row).forEach((key) => {
        if (key === key.toUpperCase()) {
          tempValues = {
            ...tempValues,
            [key]: row[key],
          };
        }
      });
      setSelectedClient(tempValues);
      setIsModalOpen(true);
    }
  };

  const handleCancelClientEdition = () => {
    setIsModalOpen(false);
    setSelectedClient(null);
  };

  const handleValidateClientEdition = (values) => {
    updateClient(values);
    setIsModalOpen(false);
    setSelectedClient(null);
  };

  const handleChangeShowExpiredClient = (e) => {
    setIsShowingExpiredClient(e.target.checked);
  };

  const rowClass = () => {
    if (!roleAssocationEditStatus) {
      return StyledRow;
    }
  };

  const handlePaste = ({
    sourceColumnKey,
    sourceRow,
    targetColumnKey,
    targetRow,
  }) => {
    if (
      clientsColumns[sourceColumnKey].type !==
      clientsColumns[targetColumnKey].type
    ) {
      handleCopyPasteDifferentType();
      return targetRow;
    } else {
      return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
    }
  };

  const theme = useTheme();

  return (
    <StyledGlobalDivTabData theme={theme}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "inherit",
        }}
      >
        <h1>Liste des dossiers client</h1>
        <ButtonComponent
          disabled={isAddingClient}
          title={
            roleAssocationEditStatus ? "Sauvegarder" : "Modification rapide"
          }
          onClickMethod={handleEditClientClick}
        />
        {roleAssocationEditStatus && (
          <ButtonComponent
            title={"Annuler"}
            onClickMethod={handleCancelEdit}
            disabled={isAddingClient}
          />
        )}
        <FormControlLabel
          control={<Switch checked={isShowingExpiredClient} />}
          label="Afficher dossiers expirés"
          onChange={handleChangeShowExpiredClient}
          labelPlacement="start"
        />
        <ButtonComponent
          variant="contained"
          title={"Ajouter client"}
          disabled={isAddingClient || roleAssocationEditStatus}
          onClickMethod={() => {
            setIsAddingClientStatus(true);
          }}
        />
      </div>
      <div style={{ width: "80vw", height: "inherit", marginTop: "2vh" }}>
        <DataGrid
          onPaste={handlePaste}
          style={{ height: "90%" }}
          headerRowHeight={100}
          rowClass={rowClass}
          columns={columns}
          rows={filteredRows}
          onRowsChange={handleRowsChange}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          onRowClick={handleRowClick}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
            filterable: true,
          }}
        />
        <ClientTotal clientsNumber={filteredRows.length} />
      </div>
      {selectedClient && (
        <EditClientComponent
          tabsList={tabsList}
          client={selectedClient}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancelClientEdition={handleCancelClientEdition}
          handleValidateClientEdition={handleValidateClientEdition}
        />
      )}
      {isAddingClient && (
        <AddClientContainer
          isAddingClient={isAddingClient}
          setIsAddingClientStatus={setIsAddingClientStatus}
        />
      )}
    </StyledGlobalDivTabData>
  );
};

export default GestionClientsComponent;
