/* eslint-disable import/no-anonymous-default-export */
import { createSelector } from "reselect";
import { CLIENTS_HEADER } from "../tab/utils";
import { userSelectors } from "../user";
import BooleanHeaderFilterContainer from "../../containers/TabHeaders/BooleanHeaderFilterContainer";
import BooleanEditor from "../../components/TabEditor/BooleanEditor";

import YearEditor from "../../components/TabEditor/YearEditor";
import TextHeaderWithFilterContainer from "../../containers/TabHeaders/TextHeaderWithFilterContainer";

const clientList = (state) => state.client.clientList;
const tabSelected = (state) => state.tabs.tabSelected;
const isLoadingClients = (state) => state.client.clientListLoading;
const yearSelected = (state) => state.client.yearSelected;
const tabsConfig = (state) => state.tabs.tabsConfig;

const clientLinkedTab = createSelector(
  [clientList, tabSelected, yearSelected],
  (clientList, tabSelected, yearSelected) =>
    clientList.filter(
      (client) =>
        client.onglets.includes(tabSelected) &&
        (client.expiration_year
          ? client.expiration_year >= yearSelected
          : true) &&
        (client.date_debut_collaboration
          ? yearSelected >= client.date_debut_collaboration
          : true)
    )
);

const clientsConfigColumns = createSelector(
  [clientLinkedTab, userSelectors.userList],
  (clients = [], users) => {
    const finalColumn = [];
    finalColumn.push(
      ...CLIENTS_HEADER(users, clients).map((column) => {
        delete column.cellClass;
        return column;
      })
    );
    return finalColumn;
  }
);

const clientsConfigRows = createSelector(
  [clientsConfigColumns, clientList],
  (columns, clients = []) => {
    let ROWS = [];
    if (clients.length > 0) {
      const tempRows = clients.map((client) => {
        let row = {};
        columns.forEach((column) => {
          row = {
            ...row,
            [column.key]: client[column.key],
          };
        });
        return { ...row, ...client };
      });
      ROWS.push(...tempRows);
    }
    return ROWS;
  }
);

const clientsGeneralConfigColumns = createSelector(
  [clientList, userSelectors.userList, tabsConfig],
  (clients = [], users, tabsConfig) => {
    let finalColumn = {};

    CLIENTS_HEADER(users, clients).forEach((column) => {
      delete column.cellClass;
      finalColumn = {
        ...finalColumn,
        [column.key]: column,
      };
    });
    Object.keys(tabsConfig).forEach((tabId) => {
      finalColumn = {
        ...finalColumn,
        [tabId]: {
          key: tabId,
          name: tabsConfig[tabId].name,
          type: "boolean",
          admin: true,
          resizable: true,
          width: 150,
          headerRenderer: (p) => (
            <BooleanHeaderFilterContainer
              columnType="boolean"
              columnName={tabsConfig[tabId].name}
              columnKey={tabId}
            />
          ),
          formatter: (props) => <BooleanEditor {...props} disabled />,
          editor: (props) => <BooleanEditor {...props} />,
          editorOptions: {
            onNavigation(e) {
              const { key } = e;
              if (key === "Tab") {
                return true;
              }
              return false;
            },
            editOnClick: true,
            commitOnOutsideClick: true,
          },
        },
      };
    });
    finalColumn = {
      ...finalColumn,
      date_debut_collaboration: {
        key: "date_debut_collaboration",
        name: "Date début Collaboration",
        type: "date",
        admin: true,
        resizable: true,
        width: 150,
        headerRenderer: (p) => (
          <TextHeaderWithFilterContainer
            columnType="text"
            columnName={p.column.name}
            columnKey={p.column.key}
          />
        ),
        editor: YearEditor,
        editorOptions: {
          onNavigation(e) {
            const { key } = e;
            if (key === "Tab") {
              return true;
            }
            return false;
          },
          editOnClick: true,
          commitOnOutsideClick: true,
        },
      },
    };
    return finalColumn;
  }
);

const clientsGeneralConfigRows = createSelector(
  [clientsGeneralConfigColumns, clientList, tabsConfig],
  (columns, clients = [], tabsConfig) => {
    let ROWS = [];
    if (clients.length > 0) {
      const tempRows = clients.map((client) => {
        let row = {};
        Object.keys(columns).forEach((columnKey) => {
          row = {
            ...row,
            [columnKey]: tabsConfig[columnKey]
              ? client.onglets.includes(columnKey)
              : client[columnKey],
          };
        });
        return { ...row, ...client };
      });
      ROWS.push(...tempRows);
    }
    return ROWS;
  }
);

export default {
  tabSelected,
  clientLinkedTab,
  isLoadingClients,
  clientsConfigRows,
  clientsGeneralConfigRows,
  clientsGeneralConfigColumns,
  clientsConfigColumns,
  yearSelected,
};
