import { connect } from "react-redux";

import { tabActions, tabSelectors } from "../store/tab";

import TabDataComponent from "../components/TabData/index";
import { clientActions, clientSelectors } from "../store/client";
import { toastActions } from "../store/toast";
import { updateTabContent } from "../services/tab";

export default connect(
  (state) => ({
    tabSelected: tabSelectors.tabSelected(state),
    tabSelectedRows: tabSelectors.tabSelectedRows(state),
    tabSelectedConfig: tabSelectors.tabSelectedConfig(state),
    tabSelectedColumnHeader: tabSelectors.tabSelectedColumnHeader(state),
    yearSelected: clientSelectors.yearSelected(state),
    filters: tabSelectors.filtersTabSelected(state),
  }),
  (dispatch) => ({
    updateTabContent: (columns, tabID, year) => {
      dispatch(tabActions.startGettingTabs());
      return updateTabContent(columns, tabID, year)
        .then((data) => {
          dispatch(tabActions.updateTab(tabID, data));
          dispatch(toastActions.updateToast("Onglet mis à jour avec succès"));
        })
        .catch((err) => {
          dispatch(toastActions.updateToast(err.message, "error"));
        });
    },
    resetFilters: (tabSelected) => {
      dispatch(tabActions.resetFilterByTab(tabSelected));
    },
    updateYearPicked: (newYear) => {
      dispatch(clientActions.updateYearSelected(newYear));
    },
    startGettingTabs: () => {
      dispatch(tabActions.startGettingTabs());
    },
    handleCopyPasteDifferentType: () => {
      dispatch(
        toastActions.updateToast(
          "Attention tu peux pas copier 2 colonnes de types différents",
          "error"
        )
      );
    },
  }),
  (stateToProps, dispatchToProps, ownProps) => ({
    ...stateToProps,
    ...dispatchToProps,
    ...ownProps,
    updateColumns: (columns) => {
      return dispatchToProps.updateTabContent(
        columns,
        stateToProps.tabSelected,
        stateToProps.yearSelected
      );
    },
    updateYearPicked: (newYear) => {
      dispatchToProps.resetFilters(stateToProps.tabSelected);
      dispatchToProps.updateYearPicked(newYear);
    },
  })
)(TabDataComponent);
