/* eslint-disable no-unused-vars */
import { forwardRef, useContext, useEffect, useMemo, useState } from "react";
import ButtonComponent from "../Widgets/Button";
import { UserContext } from "../../context/user/UserContext";
import { Alert } from "@mui/material";
import { StyledGlobalDivTabData, StyledDataGrid } from "./styles";
import { useTheme } from "@mui/material";
import { checkRightFilter } from "../TabHeaders/utils";
import SettingsIcon from "@mui/icons-material/Settings";
import SaveIcon from "@mui/icons-material/Save";
import FilterResumeContainer from "../../containers/FilterResumeContainer";
import ClientTotal from "../ClientTotal";
import YearPickerContainer from "../../containers/YearPickerContainer";

const TabData = ({
  tabSelectedColumnHeader,
  tabSelectedRows,
  tabSelectedConfig,
  updateColumns,
  yearSelected,
  updateYearPicked,
  filters,
  handleSwitchMode,
  handleCopyPasteDifferentType,
}) => {
  const [yearPicked, setYearPicked] = useState(new Date().getFullYear());

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [userContext] = useContext(UserContext);
  const [sortColumns, setSortColumns] = useState([]);
  const [columnsUpdatedRows, setColumnsUpdatedRows] = useState({});

  const [clientEditStatus, setClientEditStatus] = useState(false);
  const [columnsEditStatus, setColumnsEditStatus] = useState(true);

  const [isRowModified, setIsRowModified] = useState(false);

  const filteredRows = useMemo(() => {
    if (filters) {
      return rows.filter((r) => {
        return Object.keys(filters)
          .map((filterKey) => {
            return checkRightFilter(
              filters[filterKey].type,
              r[filterKey],
              filters,
              filterKey
            );
          })
          .every((i) => i);
      });
    } else {
      return rows;
    }
  }, [rows, filters]);

  useEffect(() => {
    setYearPicked(yearSelected);
  }, [yearSelected]);

  useEffect(() => {
    setColumns(
      tabSelectedColumnHeader.map((column) => ({
        ...column,
        editable: column.admin ? clientEditStatus : columnsEditStatus,
      }))
    );
  }, [
    clientEditStatus,
    setColumns,
    tabSelectedColumnHeader,
    columnsEditStatus,
  ]);

  useEffect(() => {
    setRows(tabSelectedRows);
  }, [tabSelectedRows, setRows]);

  const handleRowsChange = (rows, data) => {
    setRows(rows);
    setColumnsUpdatedRows({
      ...columnsUpdatedRows,
      [rows[data.indexes[0]].id]: {
        ...rows[data.indexes[0]],
        year: yearPicked,
      },
    });
  };

  useEffect(() => {
    if (Object.keys(columnsUpdatedRows).length > 0) {
      setIsRowModified(true);
    }
  }, [columnsUpdatedRows]);

  const handleEditColumnsClick = () => {
    if (columnsEditStatus) {
      updateColumns(columnsUpdatedRows).then(() => {
        setColumnsUpdatedRows({});
      });
      setIsRowModified(false);
    }
  };

  const handleChangeSelectedYear = (newYear) => {
    setYearPicked(newYear.getFullYear());
    updateYearPicked(newYear.getFullYear());
  };

  const handlePaste = ({
    sourceColumnKey,
    sourceRow,
    targetColumnKey,
    targetRow,
  }) => {
    if (
      tabSelectedConfig.columns[sourceColumnKey].type !==
      tabSelectedConfig.columns[targetColumnKey].type
    ) {
      handleCopyPasteDifferentType();
      return targetRow;
    } else {
      return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
    }
  };

  const theme = useTheme();
  return (
    <StyledGlobalDivTabData theme={theme} isAdmin={userContext.profile.isAdmin}>
      <div
        style={{
          minHeight: "95px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        {userContext.profile.isAdmin && (
          <ButtonComponent
            variant="contained"
            endIcon={<SettingsIcon />}
            title="Mode configuration"
            onClickMethod={handleSwitchMode}
          />
        )}
        <div>
          {!tabSelectedConfig?.visibility && (
            <Alert severity="error">
              Attention cet onglet n'est actuellement pas visible
            </Alert>
          )}
          <FilterResumeContainer />
        </div>

        {isRowModified && (
          <ButtonComponent
            title={"Sauvegarder"}
            onClickMethod={handleEditColumnsClick}
            endIcon={<SaveIcon />}
          />
        )}
        {!isRowModified && <YearPickerContainer />}
      </div>
      <div style={{ width: "100%", height: "92vh" }}>
        <StyledDataGrid
          theme={theme}
          onPaste={handlePaste}
          headerRowHeight={100}
          isVisible={tabSelectedConfig?.visibility}
          columns={columns}
          rows={filteredRows}
          onRowsChange={handleRowsChange}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
            filterable: true,
          }}
        />
        <ClientTotal clientsNumber={filteredRows.length} />
      </div>
    </StyledGlobalDivTabData>
  );
};
export default TabData;
