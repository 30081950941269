import React from "react";
import { Route, Routes } from "react-router-dom";

import {
  LOGIN,
  TAB,
  CONFIG_ONGLETS,
  CONFIG_USERS,
  CONFIG_CLIENTS,
} from "./utils";
import Login from "../components/Login";

import PrivateOutlet from "./PrivateRoute";
import OngletWrapperContainer from "../containers/OngletWrapperContainer";
import GestionOngletsContainer from "../containers/GestionOngletsContainer";
import GestionUtilisateursContainer from "../containers/GestionUtilisateursContainer";
import GestionClientsContainer from "../containers/GestionClientsContainer";
import DashboardContainer from "../containers/DashboardContainer";

const RoutesComponent = () => {
  return (
    <Routes>
      <Route path={LOGIN} element={<Login />} />
      <Route path={"/"} element={<PrivateOutlet />}>
        <Route path={"home"} element={<DashboardContainer />} />
        <Route path={""} element={<DashboardContainer />} />
        <Route path={CONFIG_ONGLETS} element={<GestionOngletsContainer />} />
        <Route path={CONFIG_USERS} element={<GestionUtilisateursContainer />} />
        <Route path={CONFIG_CLIENTS} element={<GestionClientsContainer />} />
        <Route path={TAB} element={<OngletWrapperContainer />} />
      </Route>
    </Routes>
  );
};

export default RoutesComponent;
