import { connect } from "react-redux";

import { tabSelectors } from "../store/tab";

import DashboardTable from "../components/Dashboard/DashboardTable";

export default connect(
  (state) => ({
    tabsColumnsMapNamed: tabSelectors.tabsColumnsMapNamed(state),
  }),
  (dispatch) => ({}),
  (stateToProps, dispatchToProps, ownProps) => ({
    ...stateToProps,
    ...dispatchToProps,
    ...ownProps,
  })
)(DashboardTable);
