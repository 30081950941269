/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useFormik } from "formik";
import MyForm from "../Widgets/Form";
import InputTextField from "../Widgets/InputTextField";
import { StyledDiv } from "./styles";
import AlertComponent from "../Widgets/Alert";

import { CircularProgress } from "@mui/material";
import { useMutation } from "react-query";
import { addTab } from "../../services/tab";
import ButtonComponent from "../Widgets/Button";

const GestionOngletsComponent = ({ tabsList, updateTabsConfig }) => {
  const initialValues = {
    name: "",
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      mutation.mutate(formik.values);
    },
  });

  const mutation = useMutation((params) => addTab(params), {
    onSuccess: (data) => {
      updateTabsConfig(data);
      formik.resetForm({ values: initialValues });
    },
    onError: () => {},
  });

  return (
    <StyledDiv>
      {mutation.isError && (
        <AlertComponent
          severity="error"
          msg={`Erreur lors de l'ajout de l'onglet ${mutation.error.message}`}
        />
      )}
      <h1>Liste des onglets</h1>

      {mutation.isLoading ? (
        <CircularProgress style={{ alignSelf: "center" }} />
      ) : (
        <>
          {tabsList.map((onglet) => (
            <div key={onglet.name}>{onglet.name}</div>
          ))}
          <MyForm>
            <InputTextField
              formik={formik}
              formikItemKey="name"
              label="Onglet"
            />
          </MyForm>
          <ButtonComponent
            submit
            title="Valider"
            onClickMethod={() => {
              formik.handleSubmit();
            }}
          />
        </>
      )}
    </StyledDiv>
  );
};

export default GestionOngletsComponent;
