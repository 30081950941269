import { axiosInstance } from "../App";

export const getTabData = async (ongletID, year) => {
  return axiosInstance
    .get("tab", { params: { onglet: ongletID, year } })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const updateColumnsTabs = (columns, tabID) => {
  return axiosInstance
    .post("tab/column", { columns: columns, tabID: tabID })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const updateTabVisibility = (visibility, tabID) => {
  return axiosInstance
    .post("tab/visibility", { visibility: visibility, tabID: tabID })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const updateLinkedStatus = (linked, tabID, clientsColumnsVisibled) => {
  return axiosInstance
    .post("tab/linked", { linked: linked, tabID: tabID, clientsColumnsVisibled })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const addTab = ({ name }) => {
  return axiosInstance
    .put("tab", { onglet: name })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const updateTabContent = (columns, tabID, year) => {
  return axiosInstance
    .post("tab/content", { tabID, columns, year })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};
