import { useEffect, useState } from "react";
import ButtonComponent from "../Widgets/Button";
import MyModal from "../Widgets/Modal";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useTheme } from "@mui/material";
import { useMutation } from "react-query";
import { updateColumnsTabs } from "../../services/tab";
import EditVisibility from "./EditVisibility";
import ColumnEdition from "./ColumnEdition";
import EditLinked from "./EditLinked";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { StyledModuleConfig } from "./styles";
const TabConfig = ({
  tabSelected,
  tabsConfig,
  getOngletsConfig,
  handleSwitchMode,
}) => {
  useEffect(() => {
    if (tabsConfig[tabSelected]) {
      setMyColumns(
        tabsConfig[tabSelected].columns.sort(
          (c1, c2) => c1.position - c2.position
        )
      );
      setExistingColumns(
        tabsConfig[tabSelected].columns.sort(
          (c1, c2) => c1.position - c2.position
        )
      );
    }
  }, [tabsConfig, tabSelected]);

  const [openModal, setModalOpened] = useState(false);
  const theme = useTheme();

  const [myColumns, setMyColumns] = useState([]);
  const [existingColumns, setExistingColumns] = useState([]);

  const [modificationsCount, setModificationsCount] = useState(0);

  const submitColumns = () => {
    setModalOpened(false);
    mutation.mutate();
  };

  const mutation = useMutation(
    () => updateColumnsTabs(myColumns, tabSelected),
    {
      onSuccess: () => {
        getOngletsConfig();
      },
      onError: () => {},
    }
  );

  const [isEditingColumn, setIsEditingColumn] = useState(false);
  const [isAddingColumn, setIsAddingColumn] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "inherit",
        maxWidth: "90vw",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90vw",
        }}
      >
        <ButtonComponent
          style={{ width: "20%" }}
          variant="contained"
          endIcon={<RotateLeftIcon />}
          title="Mode edition"
          onClickMethod={handleSwitchMode}
        />
        <h4 style={{ marginLeft: "1vw" }}>
          Configuration de l'onglet {tabSelected.toUpperCase()}
        </h4>
        <ColumnEdition
          myColumns={myColumns}
          setMyColumns={setMyColumns}
          setModificationsCount={setModificationsCount}
          modificationsCount={modificationsCount}
          isAddingColumn={isAddingColumn}
          setIsAddingColumn={setIsAddingColumn}
          isEditingColumn={isEditingColumn}
          setIsEditingColumn={setIsEditingColumn}
        />
        {!isEditingColumn && !isAddingColumn && modificationsCount > 0 && (
          <ButtonComponent
            color="secondary"
            variant="contained"
            submit
            title="Sauvegarder"
            onClickMethod={() => {
              setModalOpened(true);
            }}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          gap: "0px 1%",
          paddingTop: "10px",
        }}
      >
        <StyledModuleConfig theme={theme}>
          <EditVisibility
            tabConfig={tabsConfig[tabSelected]}
            tabSelected={tabSelected}
            getOngletsConfig={getOngletsConfig}
          />
        </StyledModuleConfig>
        <StyledModuleConfig theme={theme}>
          <EditLinked
            tabConfig={tabsConfig[tabSelected]}
            tabSelected={tabSelected}
            getOngletsConfig={getOngletsConfig}
          />
        </StyledModuleConfig>
      </div>
      <ConfirmationModal
        openModal={openModal}
        setModalOpened={setModalOpened}
        submitColumns={submitColumns}
        myColumns={myColumns}
        existingColumns={existingColumns}
        theme={theme}
      />
    </div>
  );
};

const ConfirmationModal = ({
  openModal,
  setModalOpened,
  submitColumns,
  myColumns,
  existingColumns,
}) => (
  <MyModal
    title="Confirmation des changements de colonnes"
    open={openModal}
    handleCancel={() => {
      setModalOpened(false);
    }}
    handleValidate={submitColumns}
  >
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        Colonnes existantes:
        {existingColumns.map((col) => (
          <span key={col.name}>
            {col.name} {col.subName}
          </span>
        ))}
      </div>

      <ArrowRightAltIcon />
      <div style={{ display: "flex", flexDirection: "column" }}>
        Nouvelles colonnes:
        {myColumns.map((col) => (
          <span key={col.name}>
            {col.name} {col.subName}
          </span>
        ))}
      </div>
    </div>
  </MyModal>
);
export default TabConfig;
